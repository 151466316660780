import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';

import Copyright from '../../copyright/Copyright';
import CoaiderLogo from '../../../images/coaider-logo.png';
import { AppRoutes } from '../../../routes/AppRoutes';

const AboutUsScreen = () => {

    return (
        <Container style={{ marginTop: 100 }}>
            <Typography variant='h2' align="center" style={{ padding: '4px 0' }}>
                What is CoAider?
            </Typography>
            <Typography variant='body1'>
                CoAider is created to be your aid to get quick answers to general questions.
            </Typography>
            <Typography variant="body2" style={{ padding: '8px 0' }} gutterBottom>
                Our goal is to create a safe space for people to ask for help and to receive assistance from others.
                We believe that everyone has something to offer, and that by coming together, we can make a positive impact on the world.
            </Typography>
            <Typography variant="overline" display='block' color={'secondary'} align="center" sx={{ mt: 2, mb: 2 }} gutterBottom>
                <Box component="img" src={CoaiderLogo} alt="CoAider Logo" align="center"
                    sx={{
                        width: '30px',
                        height: '30px',
                        animation: "spin 10s linear infinite",
                        "@keyframes spin": {
                            "0%": {
                                transform: "rotate(360deg)",
                            },
                            "100%": {
                                transform: "rotate(0deg)",
                            },
                        },
                    }}
                />
                <Link align="center" component={RouterLink} to={AppRoutes.home.path} underline="hover"
                    style={{ margin: '6px' }}>See CoAider in action</Link>
            </Typography>
            <Typography variant="overline" display='block' color={'primary'} align="center" sx={{ mt: 2, mb: 2 }} gutterBottom>
                CoAider is powered by AI and the community.
            </Typography>
            <Copyright />
        </Container>
    );
}

export default AboutUsScreen;