import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import CoAiderAvatar from '../../../images/coaider-avatar.png';
import AidSearchScreen from '../AidSeach/AidSearchScreen';

const messageGroup = {
    recent: 'recent',
    favorited: 'favorited', 
    archived: 'archived',
}

function refreshMessages(selectedGroup) {
    // const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

    // return Array.from(new Array(10)).map(
    //     () => messageExamples[getRandomInt(messageExamples.length)],
    // );

    if(selectedGroup === messageGroup.favorited) {
        return messageExamples.filter((message) => message.isFavorite);
    }
    else if (selectedGroup === messageGroup.archived) {
        return messageExamples.filter((message) => message.archivedAt !== null);
    }
    else {
        return messageExamples;
    }
}

const AidListScreen = () => {
    const [currentGroup, setMessageGroup] = React.useState(messageGroup.recent);
    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);
    const [messages, setMessages] = React.useState(() => refreshMessages());

    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
        setMessages(refreshMessages(currentGroup));
    }, [value, setMessages]);

    return (
        <Box sx={{ pb: 5, pt: 5}} ref={ref}>
            <CssBaseline />
            <List>
                {messages.map(({ question, reply }, index) => (
                    <ListItemButton key={index}>
                        <ListItemAvatar>
                            <Avatar alt="Profile Picture" src={CoAiderAvatar} sx={{ width: 30, height: 30}}/>
                        </ListItemAvatar>
                        <ListItemText primary={question} secondary={reply} />
                    </ListItemButton>
                ))}
                {messages.length === 0 && <Typography variant="h6" align="center">You don't have any messages here!</Typography>}
            </List>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <AidSearchScreen />
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setMessageGroup(newValue);
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Recents" value={messageGroup.recent} icon={<RestoreIcon />} />
                    <BottomNavigationAction label="Favorites" value={messageGroup.favorited} icon={<FavoriteIcon />} />
                    <BottomNavigationAction label="Archive" value={messageGroup.archived} icon={<ArchiveIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}

const messageExamples = [
    {
        question: 'What is Omega 3 good for?',
        reply: "Omega-3 fatty acids are essential nutrients that offer various health benefits. The three main types of omega-3 fatty acids are alpha-linolenic acid (ALA), eicosapentaenoic acid (EPA), and docosahexaenoic acid (DHA)",
        askedAt: '2024-02-04 18:00:01',
        answeredAt: '2024-02-04 18:00:01',
        isFavorite: false,
        archivedAt: null,
    },
    {
        question: 'How does Vitamin D boost your health?',
        reply: `Vitamin D is a crucial nutrient that plays a significant role in several essential functions within the body`,
        askedAt: '2024-02-04 18:00:01',
        answeredAt: '2024-02-04 18:00:01',
        isFavorite: false,
        archivedAt: null,
    },
    {
        question: 'What is the best way to get Vitamin D?',
        reply: `The best way to get vitamin D is through a combination of sunlight exposure, dietary sources, and, if necessary, supplements.`,
        askedAt: '2024-02-04 18:10:01',
        answeredAt: '2024-02-04 18:10:02',
        isFavorite: true,
        archivedAt: null,
    },
    {
        question: 'How about Omega 3?',
        reply: `Obtaining omega-3 fatty acids is essential for overall health, and there are several ways to ensure an adequate intake: Fatty Fish, Flaxseeds, Chia Seeds, Canola Oil, Hemp Seeds, Fish Oil Capsules, Algal Oil.`,
        askedAt: '2024-02-04 18:10:01',
        answeredAt: '2024-02-04 18:10:02',
        isFavorite: false,
        archivedAt: null,
    },
];

export default AidListScreen;