import React, {  } from 'react';
import { Container } from '@mui/material';

import AidListScreen from '../AidList/AidListScreen';

import "./UserDashboardScreen.css";
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes/AppRoutes';

const UserDashboardScreen = ({ user }) => {
    const aids = [];
    if(!user) {
        return (
            <Navigate to={AppRoutes.login.path} replace />
        )
    }
    return (
        <Container spacing={2}>
            <AidListScreen />
        </Container>
    );
};

export { UserDashboardScreen };

