import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';

const ProtectedRoute = ({
    user,
    redirectPath = AppRoutes.login.path,
    children
}) => {

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;