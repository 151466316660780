export const AppRoutes = {
    home: {
        path: "/",
        link: () => "/dashboard",
    },
    about: {
        path: "/about",
        link: () => "/about",
    },
    login: {
        path: "/login",
        link: () => "/login",
    },
    register: {
        path: "/register",
        link: () => "/register",
    },
    userDashboard: {
        path: "/dashboard",
        link: () => "/dashboard",
    },
    userProfile: {
        path: "/profile",
        link: () => "/profile",
    },
};
