import React, { useState } from 'react';
import { Container } from '@mui/material';

import AppHeaderBar from "../screens/AppHeaderBar/AppHeaderBar";
import { UserLoginScreen, UserDashboardScreen } from "../screens";

import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppRoutes } from '../../routes/AppRoutes';
import ProtectedRoute from '../../routes/ProtectedRoute';
import UserProfileScreen from '../screens/UserProfile/UserProfileScreen';
import AboutUsScreen from '../screens/AboutUs/AboutUsScreen';
import UserRegistrationScreen from '../screens/UserRegistration/UserRegistrationScreen';

const App = () => {

  const [user, setUser] = useState(null);
  const handleLogin = () => setUser({ id: '1', name: "John Doe" });
  const handleLogout = () => setUser(null);

  return (
    <Router>
      <Container className="app-container">
        <AppHeaderBar user={user} />
        <Routes>
          <Route path={AppRoutes.about.path} element={
            <AboutUsScreen />
          } />
          <Route path={AppRoutes.login.path} element={
            <UserLoginScreen onLogin={handleLogin} />
          } />
          <Route path={AppRoutes.register.path} element={
            <UserRegistrationScreen />
          } />
          <Route element={<ProtectedRoute user={user} />}>
            <Route path={AppRoutes.home.path} element={
              <UserDashboardScreen user={user} />} />
            <Route path={AppRoutes.userDashboard.path} element={
              <UserDashboardScreen user={user} />} />
          </Route>
          <Route path={AppRoutes.userProfile.path} element={
            <ProtectedRoute user={user}>
              <UserProfileScreen user={user} />
            </ProtectedRoute>
          } />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;