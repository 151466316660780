import { Typography } from "@mui/material";

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4, mb: 4 }}>
            {'CoAider © '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;